import type { FC } from "react"
import React from "react"
import { Flex, Text, Stack, Tooltip, Icon } from "@chakra-ui/react"

import ProposalGovernance from "common/components/columns/ProposalGovernance"
import Link from "common/components/Link"
import type { Organization, Proposal, Governor, Vote } from "query/graphql"
import { ProposalStatus } from "query/graphql"
import { getOrganizationIcon } from "organization/helpers/organization"
import GovernanceAvatar from "governance/components/GovernanceAvatar"
import ProposalStatusTag from "proposal/components/ProposalStatusTag"
import { useDescriptionException } from "proposal/hooks/useDescriptionException"
import { CircleInfo } from "ui/components/icons/font-awesome/CircleInfo"
import { ROUTES } from "common/constants/routes"
import { DATE_FORMATS } from "common/constants/date"
import { getDateFromTimestamp, getTimestampFromIetf } from "common/helpers/date"
import { getWhiteLabelRoute } from "whitelabel/utils/breadcrumb"
import ProposalVotesUser from "common/components/columns/ProposalVotesUser"

type ProposalIdentityProps = {
  proposal: Proposal
  governor: Governor
  isWhiteLabel?: boolean
  decimals: number
  slug: string
  organization: Organization
  voterVote?: Vote
  displayGovernorName?: boolean
}

function truncateText(text: string, maxLength: number): string {
  if (text.length <= maxLength) {
    return text
  }

  return text.slice(0, maxLength) + "..."
}

const ProposalIdentity: FC<ProposalIdentityProps> = ({
  proposal,
  governor,
  isWhiteLabel = false,
  decimals,
  organization,
  slug,
  displayGovernorName = false,
  voterVote,
}) => {
  const { title, disabled: isDisabledProposal } = useDescriptionException({
    governanceId: governor.id,
    proposal,
  })

  const { timelockId } = governor

  const getCreatedDateLabel = () => {
    const { createdAt, block } = proposal

    if (!createdAt) {
      return null
    }

    const dateToShow = block?.timestamp || createdAt
    const timestamp = getTimestampFromIetf(dateToShow)
    const date = getDateFromTimestamp(timestamp)
    const createdDateLabel = date.format(DATE_FORMATS.monthThYear)

    return createdDateLabel
  }

  const createdDateLabel = getCreatedDateLabel()

  return (
    <Stack isInline align="center" minW="300px" spacing={4}>
      <Flex direction="column" maxW="lg">
        <Stack isInline align="center" spacing={2}>
          <Link
            href={
              isWhiteLabel
                ? getWhiteLabelRoute(
                    ROUTES.governance.proposal.id(
                      slug,
                      proposal.onchainId ?? proposal.id,
                      organization.governorIds.length > 1
                        ? proposal.governor.id
                        : "",
                    ),
                  )
                : ROUTES.governance.proposal.id(
                    slug,
                    proposal.onchainId ?? proposal.id,
                    organization.governorIds.length > 1
                      ? proposal.governor.id
                      : "",
                  )
            }
          >
            <Text
              color={isDisabledProposal ? "gray.400" : ""}
              data-qa="proposals-name"
              fontWeight="bold"
              mb={1}
              noOfLines={1}
              textStyle="md"
            >
              {truncateText(title, 60)}
            </Text>
          </Link>
          {proposal.status === ProposalStatus.Succeeded ? (
            <Tooltip
              label="This proposal needs to be queued"
              placement="top-end"
            >
              <Icon as={CircleInfo} h={3} w={3} />
            </Tooltip>
          ) : null}
          {proposal.status === ProposalStatus.Queued ||
          proposal.status === ProposalStatus.Pendingexecution ? (
            <Tooltip
              label="This proposal needs to be executed"
              placement="top-end"
            >
              <Icon as={CircleInfo} h={3} w={3} />
            </Tooltip>
          ) : null}
        </Stack>

        <Stack direction="column" spacing={1}>
          {!isDisabledProposal && proposal.status ? (
            <Flex align="center" data-qa="proposals-status" direction="row">
              <ProposalStatusTag
                decimals={decimals}
                governor={governor}
                quorum={governor.quorum}
                size="sm"
                status={proposal.status}
                timelockId={timelockId}
                voteStats={proposal?.voteStats}
              />
              {createdDateLabel ? (
                <Text color="gray.500" ml={2} textStyle="body.regular.md">
                  {createdDateLabel}
                </Text>
              ) : null}
            </Flex>
          ) : null}

          {voterVote ? (
            <Flex align="center" direction="row">
              <ProposalVotesUser
                key={`governance-proposals-user-vote-${proposal.id}`}
                voterVote={voterVote}
              />
            </Flex>
          ) : null}

          {displayGovernorName ? (
            <Flex align="center" direction="row">
              <ProposalGovernance governance={governor} />
            </Flex>
          ) : null}
        </Stack>
      </Flex>
    </Stack>
  )
}

const ProposalIdentityMobile: FC<ProposalIdentityProps> = ({
  proposal,
  governor,
  organization,
  decimals,
  slug,
  voterVote,
}) => {
  const { title, disabled: isDisabledProposal } = useDescriptionException({
    governanceId: governor.id,
    proposal,
  })

  const { timelockId } = governor
  const icon = getOrganizationIcon(organization)

  return (
    <>
      <Stack isInline align="center" spacing={4}>
        <GovernanceAvatar size={8} src={icon} />
        <Flex direction="column" maxW="lg">
          <Stack isInline align="center" spacing={2}>
            <Link
              href={ROUTES.governance.proposal.id(
                slug,
                proposal.onchainId ?? proposal.id,
                organization.governorIds.length > 1 ? proposal.governor.id : "",
              )}
            >
              <Text
                as="h6"
                color={isDisabledProposal ? "gray.400" : ""}
                mb={1}
                noOfLines={1}
                textStyle="h6"
              >
                {truncateText(title, 30)}
              </Text>
            </Link>
            {proposal.status === ProposalStatus.Succeeded ? (
              <Tooltip
                label="This proposal needs to be queued"
                placement="top-end"
              >
                <Icon as={CircleInfo} h={3} w={3} />
              </Tooltip>
            ) : null}
            {proposal.status === ProposalStatus.Queued ? (
              <Tooltip
                label="This proposal needs to be executed"
                placement="top-end"
              >
                <Icon as={CircleInfo} h={3} w={3} />
              </Tooltip>
            ) : null}
          </Stack>
          <Stack isInline align="center" spacing={1}>
            {proposal.status ? (
              <ProposalStatusTag
                decimals={decimals}
                governor={governor}
                quorum={governor.quorum}
                size="sm"
                status={proposal.status}
                timelockId={timelockId}
                voteStats={proposal?.voteStats}
              />
            ) : null}
          </Stack>
        </Flex>
      </Stack>
      {voterVote ? (
        <Flex align="center" direction="row">
          <ProposalVotesUser
            key={`governance-proposals-user-vote-${proposal.id}`}
            voterVote={voterVote}
          />
        </Flex>
      ) : null}
    </>
  )
}

export { ProposalIdentity, ProposalIdentityMobile }
