import type { FC } from "react"
import React from "react"
import { Stack, Box, Text } from "@chakra-ui/react"
import { BigNumber } from "@ethersproject/bignumber"

import type { Maybe, VoteStats } from "query/graphql"
import { VoteType } from "query/graphql"
import { getWeightLabel } from "common/helpers/bignumber"

type ProposalVotesProps = {
  voteStats?: Maybe<VoteStats[]>
  decimals: number
}

const ProposalVotesAgainst: FC<ProposalVotesProps> = ({
  voteStats,
  decimals,
}) => {
  const voteStatsAgainst = voteStats?.find(
    ({ type }) => type === VoteType.Against,
  )

  if (voteStatsAgainst === undefined) return null

  const { percent, votesCount } = voteStatsAgainst

  const w = `${percent}%`
  const bigWeight = BigNumber.from(votesCount)
  const weightLabel = getWeightLabel(bigWeight, decimals)

  return (
    <Stack justify="center" spacing={1}>
      <Text color="red.500" textStyle="body.bold.md">
        {weightLabel}
      </Text>
      <Box bg="gray.50" borderRadius={2} h={1} pos="relative">
        <Box
          bg="red.500"
          borderRadius={2}
          h={1}
          left={0}
          pos="absolute"
          top={0}
          w={w}
        />
      </Box>
    </Stack>
  )
}

export default ProposalVotesAgainst
