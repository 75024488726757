import { useContext } from "react"

import type { DescriptionException } from "proposal/types/exception"
import { DescriptionExceptionsContext } from "proposal/providers/DescriptionExceptionsProvider"

type Values = {
  descriptionExceptions: DescriptionException[]
}

export const useDescriptionExceptions = (): Values => {
  const context = useContext(DescriptionExceptionsContext)

  if (!context) {
    throw new Error(
      "useDescriptionExceptions must live within DescriptionExceptionsProvider",
    )
  }

  const { descriptionExceptions } = context

  return {
    descriptionExceptions,
  }
}
