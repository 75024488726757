import type { FC } from "react"
import React from "react"
import { Flex, Text } from "@chakra-ui/react"

import type { Maybe, VoteStats } from "query/graphql"
import { getTotalWeight, getWeightLabel } from "common/helpers/bignumber"

type PProposalVotesTotal = {
  voteStats?: Maybe<VoteStats[]>
}

type ProposalTotalVotesProps = {
  proposal: PProposalVotesTotal
  decimals: number
}

const ProposalVotesTotal: FC<ProposalTotalVotesProps> = ({
  proposal,
  decimals,
}) => {
  const { voteStats } = proposal

  const participatingVotersCount = voteStats?.reduce((acc, { votersCount }) => {
    return acc + Number(votersCount)
  }, 0)

  const weights = voteStats
    ? voteStats?.map(({ votesCount }) => votesCount)
    : []
  const totalWeight = getTotalWeight(weights)
  const totalWeightLabel = getWeightLabel(totalWeight, decimals)

  return (
    <Flex align="flex-end" direction="column">
      <Text fontWeight="bold" textStyle="sm">
        {totalWeightLabel}
      </Text>
      <Text
        align="right"
        color="gray.500"
        textStyle="body.regular.md"
        width="max-content"
      >
        {`${participatingVotersCount} addresses`}
      </Text>
    </Flex>
  )
}

export default ProposalVotesTotal
