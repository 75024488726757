import type { FC } from "react"
import React from "react"
import { Stack, Text } from "@chakra-ui/react"

import type { Governor } from "query/graphql"

type ProposalGovernanceProps = {
  governance: Pick<Governor, "name">
}

const ProposalGovernance: FC<ProposalGovernanceProps> = ({ governance }) => {
  const { name } = governance

  return (
    <Stack justify="center">
      <Text color="gray.600" textStyle="body.regular.md">
        {name}
      </Text>
    </Stack>
  )
}

export default ProposalGovernance
