import type { ComponentWithAs, IconProps } from "@chakra-ui/react"

import { ProposalParticipationType, SupportType, VoteType } from "query/graphql"
import CrossMark from "ui/components/icons/CrossMark"
import { CircleCheck } from "ui/components/icons/font-awesome/CircleCheck"
import { CircleInfo } from "ui/components/icons/font-awesome/CircleInfo"
import WarningTriangleIcon from "ui/components/icons/WarningTriangleIcon"

export type VoteStyle = {
  color: string
  colorScheme: string
  icon: ComponentWithAs<"svg", IconProps>
}

const themes: VoteStyle[] = [
  {
    // orange.500
    color: "#DD6B20",
    colorScheme: "orange",
    icon: CircleInfo,
  },
  {
    // yellow.500
    color: "#D69E2E",
    colorScheme: "yellow",
    icon: CircleInfo,
  },
  {
    // teal.500
    color: "#319795",
    colorScheme: "teal",
    icon: CircleInfo,
  },
  {
    // blue.500
    color: "#3182CE",
    colorScheme: "blue",
    icon: CircleInfo,
  },
  {
    // cyan.500
    color: "#00B5D8",
    colorScheme: "cyan",
    icon: CircleInfo,
  },
  {
    // purple.500
    color: "#805AD5",
    colorScheme: "purple",
    icon: CircleInfo,
  },
  {
    // pink.500
    color: "#D53F8C",
    colorScheme: "pink",
    icon: CircleInfo,
  },
]

export const getVoteStyles = (
  supports?: string[] | null,
): Record<string, VoteStyle> => {
  // if the supports param is empty or it doesn't match to the 3 default support types,
  // then it is custom
  const isDefaultSupport =
    !supports?.length ||
    supports?.filter(
      (support) =>
        ![
          VoteType.For as string,
          VoteType.Against as string,
          VoteType.Abstain as string,
        ].includes(support),
    ).length === 0

  if (isDefaultSupport) {
    // Duplicate json to handle vote value in lowercase (e.g. "abstain") and uppercase (e.g. "ABSTAIN")
    return {
      // gray.500
      [SupportType.Abstain]: {
        color: "#718096",
        colorScheme: "gray",
        icon: CircleInfo,
      },
      [VoteType.Abstain]: {
        color: "#718096",
        colorScheme: "gray",
        icon: CircleInfo,
      },

      // red.500
      [SupportType.Against]: {
        color: "#F44061",
        colorScheme: "red",
        icon: CrossMark,
      },
      [VoteType.Against]: {
        color: "#F44061",
        colorScheme: "red",
        icon: CrossMark,
      },

      // green.500
      [SupportType.For]: {
        color: "#25C9A1",
        colorScheme: "green",
        icon: CircleCheck,
      },
      [VoteType.For]: {
        color: "#25C9A1",
        colorScheme: "green",
        icon: CircleCheck,
      },

      // gray.500
      [VoteType.Pendingabstain]: {
        color: "#718096",
        colorScheme: "gray",
        icon: CircleInfo,
      },

      // red.500
      [VoteType.Pendingagainst]: {
        color: "#F44061",
        colorScheme: "red",
        icon: CrossMark,
      },

      // green.500
      [VoteType.Pendingfor]: {
        color: "#25C9A1",
        colorScheme: "green",
        icon: CircleCheck,
      },
    }
  }

  return supports.reduce((prev, support, index) => {
    return {
      ...prev,
      [support]: themes[index],
    }
  }, {})
}

export const getParticipationTypeStyles = (): Record<string, VoteStyle> => {
  return {
    [ProposalParticipationType.Votedabstain]: {
      // gray.500
      color: "#718096",
      colorScheme: "gray",
      icon: CircleInfo,
    },
    [ProposalParticipationType.Votedagainst]: {
      // red.500
      color: "#F44061",
      colorScheme: "red",
      icon: CrossMark,
    },
    [ProposalParticipationType.Votedfor]: {
      // green.500
      color: "#25C9A1",
      colorScheme: "green",
      icon: CircleCheck,
    },
    [ProposalParticipationType.Notvoted]: {
      // gray.400
      color: "#98A2B3",
      colorScheme: "gray",
      icon: CrossMark,
    },
    [ProposalParticipationType.Notdelegate]: {
      // yellow.400
      color: "#ECC94B",
      colorScheme: "yellow",
      icon: WarningTriangleIcon,
    },
  }
}
