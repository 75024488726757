import type { FC, ReactNode } from "react"
import React, { createContext } from "react"

import type { DescriptionException } from "proposal/types/exception"

type Values = {
  descriptionExceptions: DescriptionException[]
}

// @ts-expect-error It's a good practice not to give a default value even though the linter tells you so
export const DescriptionExceptionsContext = createContext<Values>({})

type Props = {
  descriptionExceptions: DescriptionException[]
  children: ReactNode
}

export const DescriptionExceptionsProvider: FC<Props> = ({
  descriptionExceptions,
  children,
}) => {
  const value = { descriptionExceptions }

  return (
    <DescriptionExceptionsContext.Provider value={value}>
      {children}
    </DescriptionExceptionsContext.Provider>
  )
}
