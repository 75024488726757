import get from "lodash.get"
import type {
  InfiniteQueryObserverResult,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query"
import { useInfiniteQuery as useReactQueryInfinityQuery } from "@tanstack/react-query"

import type { GrpcErrorType } from "common/helpers/fetcher"
import { fetcher } from "common/helpers/fetcher"
import type { Pagination, PageInput, InputMaybe } from "query/graphql"

export type InfiniteQueryProps<
  Query,
  Variables extends { pagination?: Pagination | null },
> = UseInfiniteQueryOptions<Query> & {
  pageSize: number
  document: string
  variables: Variables
  sectionName: string
  nextPagePath: string
  getPageItems?: (lastPage: Query) => any[]
}

export const useInfiniteQuery = <
  Query,
  Variables extends { pagination?: Pagination | null },
>({
  pageSize,
  document,
  variables,
  sectionName,
  nextPagePath,
  getPageItems,
  ...options
}: InfiniteQueryProps<Query, Variables>): InfiniteQueryObserverResult<
  Query,
  unknown
> => {
  return useReactQueryInfinityQuery<Query, unknown, Query>(
    [sectionName, variables],
    async ({ pageParam }) => {
      const { pagination: initialPagination, ...otherVariables } = variables
      const initialLimit = initialPagination?.limit ?? 0

      const pagination: Pagination = pageParam
        ? { limit: initialLimit, offset: pageParam }
        : (initialPagination as Pagination)

      return fetcher.gql<Query, Variables>({
        query: document,
        variables: {
          pagination,
          ...pageParam,
          ...otherVariables,
        },
        // if there is an error on a react-query "queryFn", then it's going to
        // be bubbled to the next error boundary, and will set the data automatically
        // to "undefined"

        // react-query isn't intelligent enough to understand that "null" is a possibility
        // that should be kept in mind. Nonetheless, under the hood it seems to be handled
        // and finally returned as "undefined"
      }) as Promise<Query>
    },
    {
      getNextPageParam: (lastPage: Query, allPages: Query[]) => {
        const pageItems: any[] = getPageItems
          ? getPageItems(lastPage)
          : get(lastPage, nextPagePath)
        const pageItemsCount = pageItems?.length
        const hasPageItems = Boolean(pageItemsCount)

        const hasNextPage = hasPageItems
          ? pageItemsCount % pageSize === 0
          : false

        const nextSkip = allPages.length * pageSize
        const skip = hasNextPage ? nextSkip : undefined

        return skip
      },
      ...options,
    },
  )
}

type Input = {
  input?: InputMaybe<{
    filters?: InputMaybe<unknown>
    page?: InputMaybe<PageInput>
    sort?: InputMaybe<unknown>
  }>
}

type InfiniteCursorQueryProps<
  Query,
  Variables extends Input,
> = UseInfiniteQueryOptions<Query> & {
  document: string
  variables: Variables
  sectionName: string
  nextPagePath: string
  omittedErrors?: GrpcErrorType[]
}

export const useInfiniteCursorQuery = <Query, Variables extends Input>({
  document,
  variables,
  sectionName,
  nextPagePath,
  omittedErrors,
  ...options
}: InfiniteCursorQueryProps<Query, Variables>): InfiniteQueryObserverResult<
  Query,
  unknown
> => {
  return useReactQueryInfinityQuery<Query, unknown, Query>(
    [sectionName, variables],
    async ({ pageParam = {} }: { pageParam?: PageInput }) => {
      return fetcher.gql<Query, Variables>({
        query: document,
        variables: {
          ...variables,
          input: {
            ...variables?.input,
            page: {
              ...(variables?.input?.page || {}),
              ...pageParam,
            },
          },
        },
        omittedErrors,
        // if there is an error on a react-query "queryFn", then it's going to
        // be bubbled to the next error boundary, and will set the data automatically
        // to "undefined"

        // react-query isn't intelligent enough to understand that "null" is a possibility
        // that should be kept in mind. Nonetheless, under the hood it seems to be handled
        // and finally returned as "undefined"
      }) as Promise<Query>
    },
    {
      getNextPageParam: (lastPage: Query) => {
        const result = get(lastPage, nextPagePath)

        return result?.pageInfo?.lastCursor
          ? { afterCursor: result.pageInfo.lastCursor }
          : undefined
      },
      ...options,
    },
  )
}
