import type { FC } from "react"
import React from "react"
import { Stack, Box, Text } from "@chakra-ui/react"
import { BigNumber } from "@ethersproject/bignumber"

import type { Maybe, VoteStats } from "query/graphql"
import { VoteType } from "query/graphql"
import { getWeightLabel } from "common/helpers/bignumber"

type ProposalVotesProps = {
  voteStats?: Maybe<VoteStats[]>
  decimals: number
}

const ProposalVotesFor: FC<ProposalVotesProps> = ({ voteStats, decimals }) => {
  const voteStatsFor = voteStats?.find(({ type }) => type === VoteType.For)

  if (voteStatsFor === undefined) return null

  const { percent, votesCount } = voteStatsFor

  const w = `${percent}%`
  const bigWeight = BigNumber.from(votesCount)

  const weightLabel = getWeightLabel(bigWeight, decimals)

  return (
    <Stack justify="center" spacing={1}>
      <Text color="green.500" textStyle="body.bold.md">
        {weightLabel}
      </Text>
      <Box bg="gray.50" borderRadius={2} h={1} pos="relative">
        <Box
          bg="green.500"
          borderRadius={2}
          h={1}
          left={0}
          pos="absolute"
          top={0}
          w={w}
        />
      </Box>
    </Stack>
  )
}

export default ProposalVotesFor
