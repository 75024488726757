import { EXTERNAL_ENDPOINTS } from "common/constants/endpoints"
import { fetcher } from "common/helpers/fetcher"
import { isNumber } from "common/helpers/number"
import type { DescriptionException } from "proposal/types/exception"
import type { AccountId } from "query/graphql"

export const _getDescriptionException = ({
  descriptionExceptions,
  governanceId,
  proposalId,
  proposalOnchainId,
}: {
  descriptionExceptions: DescriptionException[]
  governanceId: AccountId
  proposalId: string
  proposalOnchainId?: string
}): {
  descriptionException?: DescriptionException
} => {
  const descriptionException = descriptionExceptions.find((exception) => {
    // If the proposalId is number, check both the proposalId and governanceId
    if (isNumber(proposalId)) {
      return (
        exception.governanceId === governanceId &&
        (exception.proposalId === String(proposalId) ||
          exception.proposalId === String(proposalOnchainId)) &&
        Boolean(exception.description)
      )
    }

    // Otherwise, check only the proposalId. It fixes a bug where multiple governors are set on an Organization
    // but the wrong governorId is sent (like Arbitrum)
    return (
      (exception.proposalId === String(proposalId) ||
        exception.proposalId === String(proposalOnchainId)) &&
      Boolean(exception.description)
    )
  })

  return { descriptionException }
}

export const getProposalTitle = (markdown: string): string => {
  const firstLineJump = markdown.trimStart().indexOf("\n")
  const hasLineJump = firstLineJump > -1
  const title = hasLineJump
    ? markdown.trimStart().slice(0, firstLineJump)
    : markdown.trimStart()

  if (title.startsWith("# ")) {
    return title.slice(2)
  }

  return title
}

export const getProposalDescription = (markdown: string): string => {
  const firstLineJump = markdown.indexOf("\n")
  const hasLineJump = firstLineJump > -1

  if (!hasLineJump) return markdown

  const description = markdown.slice(firstLineJump).trimStart()

  return description
}

type FetchDescriptionExceptionProps = {
  descriptionExceptions: DescriptionException[]
}

type FetchDescriptionExceptionResponse = {
  descriptionExceptions: DescriptionException[]
}

export const fetchDescriptionExceptionsByGovernorId = async (
  governorIds?: string[],
): Promise<FetchDescriptionExceptionProps> => {
  if (!governorIds) {
    return { descriptionExceptions: [] }
  }

  try {
    const query = `
      query DescriptionExceptions($governorIds: [String!]) {
        descriptionExceptions(where: { governanceId_in: $governorIds }) {
          description
          governanceKey
          governanceId
          proposalId
          quorum
          disabled
          hideVoteButton
        }
      }`
    const endpoint = EXTERNAL_ENDPOINTS.graphCms()
    const response = await fetcher.gql<FetchDescriptionExceptionResponse>({
      query,
      variables: { governorIds },
      endpoint,
      onError: (error) => console.log(error),
    })
    const descriptionExceptions = response?.descriptionExceptions ?? []

    return {
      descriptionExceptions,
    }
  } catch (err) {
    return {
      descriptionExceptions: [],
    }
  }
}
